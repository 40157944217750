
import TasqJob from '@/interfaces/tasqs/TasqJob';
import { getFormattedDate } from '@/utils/helpers';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class TasqWellStatusBanner extends Vue {
  @Prop({ required: true }) tasq!: TasqJob;

  get waitingOnReason() {
    return this.tasq.waitUntilReason.split('-Remarks:')[0];
  }

  get waitingOnComment() {
    return this.tasq.waitUntilReason.split('-Remarks:')[1] || 'N/A';
  }

  get waitingOnStartDate() {
    return getFormattedDate(new Date(this.tasq.snoozed.WaitStartDate));
  }

  get waitingOnEndDate() {
    return getFormattedDate(new Date(this.tasq.snoozed.UntilDate));
  }
}
